import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">AbidIqbal </span>
            from <span className="purple"> Islamabad Pakistan</span>
            <br />I am a full stack web developer and a tech enthusiast. I love
            to learn new technologies and implement them in my projects.
            <br />
            <br />
            <dl>
              <dt>full name</dt>
              <dd>- Abid Iqbal</dd>
              <dt>address</dt>
              <dd>
                - H No 7 Street 6 Block H Newcity Phase 2 , Wah Taxila
                Rawalpindi ,Wah ,47040 ,Pakistan
              </dd>
              <dt>phone number</dt>
              <dd>- +92 3215135303</dd>
              <dt>email address</dt>
              <dd>- nasir@nasiriqbal.com</dd>
            </dl>
            <br/>
            Apart from coding, some other activities that I love to do!
          </p>

          <ul>
            <li className="about-activity"></li>
            <li className="about-activity">
              <ImPointRight /> Writting Tech Blogs
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
          <footer className="blockquote-footer">Abid Iqbal</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
